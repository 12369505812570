<template>
  <v-card rounded elevation="3" outlined class="mx-1 milestones-box">
    <v-card-title>
      <v-row dense class="mb-1">
        <v-col :cols="smAndDown ? 6 : 7">
          <h2 class="title primary--text">
            {{ milestone.title | ucwords | truncate(smAndDown ? 15 : 31) }}
          </h2>
        </v-col>
        <v-spacer />
        <div class="mr-3" style="color: #223a6b">{{ boxProgress }}</div>
        <v-btn-toggle dense multiple>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                depressed
                icon
                class="primary light--text pa-4"
                v-on="on"
                @click="$emit('add-task', milestone)"
              >
                <v-icon color="white">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span> Add Task </span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                depressed
                dark
                icon
                class="primary light--text pa-4"
                v-on="on"
                @click="$emit('edit-milestone', milestone)"
              >
                <v-icon small color="white">mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span> Edit Milestone </span>
          </v-tooltip>
        </v-btn-toggle>
        <div class="d-flex ml-3 align-center pb-1">
          <v-icon @click="confirmDeleteMilestone" color="#223A6B">
            mdi-close
          </v-icon>
        </div>
      </v-row>
      <v-progress-linear
        class="rounded-3"
        color="primary"
        height="25"
        :value="Math.ceil(tasksProgress)"
      >
      </v-progress-linear>
    </v-card-title>
    <v-card-text class="box-body">
      <v-row
        dense
        align="center"
        justify="center"
        style="
          background: #223a6b;
          padding: 5px 10px;
          border-radius: 5px;
          margin-top: 5px;
        "
      >
        <v-col
          :cols="smAndDown ? 4 : 5"
          class="subtitle-2"
          style="color: white"
        >
          Task
        </v-col>
        <v-col
          cols="5"
          class="subtitle-2 pl-3 text-center"
          style="color: white"
        >
          Status
        </v-col>
        <v-col
          :cols="smAndDown ? 3 : 2"
          class="text-center subtitle-2 pl-3"
          style="color: white"
        >
          Actions
        </v-col>
      </v-row>
      <draggable
        class="tasks-drag"
        v-model="milestone.tasks"
        scroll-sensitivity="600"
        force-fallback="true"
        @change="handleSorting"
      >
        <v-row
          dense
          align="center"
          justify="center"
          class="task-row"
          style="background: #e8ebf0; border-bottom: 2px solid white"
          v-for="task in milestone.tasks"
          :key="task.id"
        >
          <v-col :cols="smAndDown ? 4 : 5">
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <span
                  class="pl-5"
                  v-on="on"
                  style="font-size: 15px; color: #223a6b; font-weight: 700"
                >
                  {{ task.title | truncate(mdAndUp ? 45 : 20) }}
                </span>
              </template>
              <span>{{ task.title }}</span>
            </v-tooltip>
          </v-col>
          <v-col cols="5" class="text-center">
            <v-chip
              pill
              small
              :class="task.status"
              class="caption align-center justify-center"
              style="font-weight: 600; font-size: 14px"
            >
              {{ task.status | ucwords }}
            </v-chip>
          </v-col>
          <v-col
            :cols="smAndDown ? 3 : 2"
            class="align-content-center text-center"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="task-icon"
                  color="primary"
                  @click="
                    $emit('edit-task', {
                      task: task,
                      milestone: milestone
                    })
                  "
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit Task</span>
            </v-tooltip>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon
                  v-on="on"
                  class="task-icon"
                  color="primary"
                  @click="confirmDeleteTask(task)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Remove Task</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </draggable>
    </v-card-text>
    <DeleteDialog
      v-model="delete_task_dialog"
      title="Delete Task"
      text-content="Are you sure you want to delete this task?"
      @delete="delete_task"
    ></DeleteDialog>
    <DeleteDialog
      v-model="delete_milestone_dialog"
      title="Delete Milestone"
      text-content="Are you sure you want to delete this milestone?<br><span class='error--text'>This will also delete all tasks in this milestone.</span>"
      @delete="delete_milestone"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import draggable from 'vuedraggable'
import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
export default {
  name: 'PerMilestone',
  props: {
    milestone: { type: Object, required: true }
  },
  components: {
    draggable,
    DeleteDialog
  },
  data: () => ({
    activeTask: null,
    delete_task_dialog: false,
    delete_milestone_dialog: false
  }),
  computed: {
    completedTasks() {
      return this.milestone.tasks.filter(
        (t) => t.status.toLowerCase() === 'completed'
      )
    },
    tasksProgress() {
      let allTasks = this.milestone.tasks.length
      return (this.completedTasks.length / allTasks) * 100
    },
    boxProgress() {
      return `${this.completedTasks.length}/${this.milestone.tasks.length}`
    }
  },
  methods: {
    handleSorting(e) {
      let new_order = this.milestone.tasks.map((i) => {
        return i.id
      })
      request
        .post('api/task/re-order', {
          milestone: parseInt(this.id),
          order: new_order
        })
        .then(({ data }) => {
          this.appSnackbar('Milestone task re-ordered!')
        })
    },
    confirmDeleteTask(task) {
      this.activeTask = task
      this.delete_task_dialog = true
    },
    delete_task() {
      this.$emit('delete-task', {
        task: this.activeTask,
        milestone: this.milestone
      })
      this.delete_task_dialog = false
    },
    confirmDeleteMilestone() {
      this.delete_milestone_dialog = true
    },
    delete_milestone() {
      this.$emit('delete-milestone', this.milestone)
      this.delete_milestone_dialog = false
    }
  }
}
</script>

<style scoped lang="scss">
.milestones-box {
  border-radius: 20px !important;
  padding: 10px;
  min-height: 500px;
  .box-body {
    max-height: 400px;
    overflow-y: auto;
  }
  .rounded-3 {
    border-radius: 12px;
  }
  .task-row {
    height: 40px;
    padding: 15px auto;
    &:hover {
      cursor: move;
      background-color: #bcc3d2;
      color: gray;
      border-radius: 10px;
    }
    .task-icon {
      margin-left: 5px;
    }
    .v-chip {
      width: 100% !important;
    }
    .Urgent {
      color: $urgent;
      background: transparent;
      font-size: 14px;
      font-weight: 700;
    }
    .Pending {
      color: $pending;
      background: transparent;
      font-size: 14px;
      font-weight: 700;
    }
    .Behind {
      color: $behind;
      background: transparent;
      font-size: 14px;
      font-weight: 700;
    }
    .Open {
      color: $open;
      background: transparent;
      font-size: 14px;
      font-weight: 700;
    }
    .completed {
      background: transparent !important;
      color: $completed !important;
      font-size: 14px;
      font-weight: 700;
    }
    .Completed {
      background: transparent !important;
      color: $completed !important;
      font-size: 14px;
      font-weight: 700;
    }
  }
}
</style>
