import request from '@/services/axios_instance'
import DeleteDialog from '@/common/DeleteDialog.vue'
import PerMilestone from './components/DynamicBox/PerMilestone.vue'
import MilestoneTabDialog from './components/MilestoneTabDialog/MilestoneTabDialog.vue'
import SelectTemplateDialog from './components/SelectTemplateDialog/SelectTemplateDialog.vue'
import _cloneDeep from 'lodash/cloneDeep'
import TaskDialog from '@/common/Dialogs/TaskDialog.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
    name: 'MilestonesTab',
    components: {
        MilestoneTabDialog,
        DeleteDialog,
        SelectTemplateDialog,
        PerMilestone,
        TaskDialog
    },

    props: {
        id: [Number, String]
    },
    watch: {
        id(val) {
            if (val) {
                this.$store.dispatch('project/fetchProjectInfo', val)
            }
        }
    },
    data: () => ({
        milestone_options: null,
        activeTask: null,
        activeMilestone: null,
        task_dialog: false,
        milestone_dialog: false,
        select_template_dialog: false,
        loading: false,

        add_dialog: false,
        edit_dialog: false,
        edit_task_dialog: false,
        delete_dialog: false,
        add_task_dialog: false,
        boxes: [],
        direction: 'top',
        is_open_speed_dial: false,
        id_to_delete: null,
        edit_item: {
            id: null,
            fields: null
        },
        edit_task_item: {
            id: null,
            index: null,
            fields: null,
            box_id: null
        },
        box_id_to_add_task: null,
        add_task_start_date: null,
        boxIdInProgress: null
    }),
    computed: {
        ...mapGetters('project', ['project_members']),
        type() {
            return this.$route.params.type || 'project'
        },
        paths() {
            return [{
                    text: 'Dashboard',
                    disabled: false,
                    route: { name: 'default-content' }
                },
                {
                    text: this.type,
                    disabled: false,
                    route: { path: `/dashboard/${this.type}/preview/${this.id}` }
                },
                { text: 'Milestones', disabled: true, route: null }
            ]
        }
    },
    created() {
        this.fetchProjectInfo(this.id)
        this.fetchMilestones()
    },
    mounted() {
        this.$event.$emit('path-change', this.paths)
    },
    methods: {
        ...mapActions('project', ['fetchProjectInfo']),
        editMilestoneTask({ task, milestone }) {
            this.activeTask = task
            this.activeMilestone = milestone
            this.task_dialog = true
        },
        addMilestoneTask(milestone) {
            this.activeTask = null
            this.activeMilestone = milestone
            this.task_dialog = true
        },
        saveMilestoneTask(payload) {
            // console.log(payload)
            this.$store.commit('set_loader', true)
            request
                .post(`api/milestone/${this.activeMilestone.id}/task`, payload)
                .then(({ data }) => {
                    let index = this.boxes.findIndex(
                        (box) => box.id === this.activeMilestone.id
                    )
                    if (~index) {
                        this.boxes[index].tasks.push(data)
                        this.appSnackbar('Milestone task added')
                    }
                    this.task_dialog = false
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        updateMilestoneTask(payload) {
            this.$store.commit('set_loader', true)
            request
                .put(
                    `api/milestone/${this.activeMilestone.id}/task/${this.activeTask.id}`,
                    payload
                )
                .then(({ data }) => {
                    let index = this.boxes.findIndex(
                        (box) => box.id === this.activeMilestone.id
                    )
                    if (~index) {
                        let index2 = this.boxes[index].tasks.findIndex(
                            (task) => task.id === this.activeTask.id
                        )
                        if (~index2) {
                            this.boxes[index].tasks.splice(index2, 1, data)
                            this.appSnackbar('Milestone task added')
                        }
                    }
                    this.task_dialog = false
                    this.activeTask = null
                    this.activeMilestone = null
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        deleteMilestoneTask({ task, milestone }) {
            this.$store.commit('set_loader', true)
            request
                .delete(`api/milestone/${milestone.id}/task/${task.id}`)
                .then(({ data }) => {
                    let index = this.boxes.findIndex((box) => box.id === milestone.id)
                    if (~index) {
                        let index2 = this.boxes[index].tasks.findIndex(
                            (ttask) => ttask.id === task.id
                        )
                        if (~index2) {
                            this.boxes[index].tasks.splice(index2, 1)
                            this.appSnackbar('Milestone task deleted')
                        }
                    }
                    this.activeTask = null
                    this.activeMilestone = null
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        handleCreateMilestone() {
            this.activeMilestone = null
            this.milestone_dialog = true
        },
        handleEditMilestone(milestone) {
            this.activeMilestone = milestone
            this.milestone_dialog = true
        },
        handleDeleteMilestone(milestone) {
            this.$store.commit('set_loader', true)
            request
                .delete(`api/project/${this.id}/milestone/${milestone.id}`)
                .then(({ data }) => {
                    let index = this.boxes.findIndex((i) => i.id === milestone.id)
                    if (~index) {
                        this.boxes.splice(index, 1)
                        this.appSnackbar('Milestone deleted.')
                    }
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        fetchMilestones() {
            this.loading = true
            request
                .get(`api/project/${this.id}/milestone?all=true`)
                .then(({ data }) => (this.boxes = data))
                .finally(() => (this.loading = false))
        },
        addNewMilestone(payload) {
            this.$store.commit('set_loader', true)
            request
                .post(`api/project/${this.id}/milestone`, payload)
                .then(({ data }) => {
                    this.boxes.push(data)
                    this.appSnackbar('New Milestone added')
                    this.milestone_dialog = false
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        updateMilestone(payload) {
            this.$store.commit('set_loader', true)
            request
                .put(
                    `api/project/${this.id}/milestone/${this.activeMilestone.id}`,
                    payload
                )
                .then(({ data }) => {
                    const index = this.boxes.findIndex(
                        (item) => item.id === this.activeMilestone.id
                    )
                    if (~index) {
                        this.boxes.splice(index, 1, data)
                        this.appSnackbar('Milestone updated')
                    }
                    this.activeMilestone = null
                    this.milestone_dialog = false
                })
                .finally(() => {
                    this.$store.commit('set_loader', false)
                    this.$event.$emit('btnloading_off', false)
                })
        },
        addTemplate(payload) {
            this.$store.commit('set_loader', true)
            request
                .post(`api/projects/${this.id}/milestone-import`, payload)
                .then(({ data }) => {
                    this.fetchMilestones()
                })
                .finally(() => {
                    this.select_template_dialog = false
                    this.$event.$emit('btnloading_off', false)
                    this.$store.commit('set_loader', false)
                })
        }
    }
}