<template>
  <CustomDialog
    :max-width="900"
    title="Add From Milestone Templates"
    ref="dialog"
    :open.sync="dialog"
    @button1="cancel"
    @click.close="cancel"
  >
    <template v-slot:content>
      <v-card flat min-height="400">
        <v-stepper flat v-model="stepper">
          <v-stepper-header
            style="box-shadow: none"
            class="border-bottom-3 mt-0 border-color--primary"
          >
            <v-stepper-step
              :rules="[() => step1_passed]"
              :complete="stepper > 1"
              step="1"
            >
              Select Templates
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step
              :rules="[() => step2_passed]"
              :complete="stepper > 2"
              step="2"
            >
              Select Milestones
            </v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3" :rules="[() => step3_passed]">
              Assign Milestone Tasks
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card min-height="300">
                <v-card-title
                  class="my-1 py-0 d-flex align-center justify-space-between"
                >
                  <strong class="primary--text mb-2 fs-20 fw-700">
                    Select Templates
                  </strong>
                  <v-checkbox
                    :disabled="!templates.length"
                    :indeterminate="
                      selected_templates.length < templates.length &&
                      selected_templates.length > 0
                    "
                    label="Select All"
                    class="mb-2 my-0"
                    hide-details="auto"
                    v-model="select_all_templates"
                  >
                  </v-checkbox>
                </v-card-title>
                <v-card-text>
                  <v-row
                    v-if="templates.length"
                    dense
                    align="start"
                    class="fullwidth flex-wrap"
                    justify="center"
                  >
                    <v-col
                      v-for="template in templates"
                      :key="template.id"
                      cols="12"
                      md="3"
                      sm="6"
                    >
                      <v-card
                        outlined
                        width="100%"
                        height="180"
                        :color="activeTemplate(template) ? 'primary' : ''"
                        elevation="5"
                        :class="{
                          'light--text': activeTemplate(template),
                          'primary--text': !activeTemplate(template)
                        }"
                        class="overflow-hidden rounded-10"
                        @click="toggleTemplate(template)"
                      >
                        <div
                          style="min-height: 100px"
                          :class="{
                            'light--text': activeTemplate(template),
                            'primary--text': !activeTemplate(template)
                          }"
                          class="
                            fs-15
                            d-flex
                            fullwidth
                            align-center
                            justify-center
                            fw-700
                            flex-wrap
                            text-center
                          "
                        >
                          {{ template.name }}
                        </div>
                        <v-divider
                          :class="{
                            white: activeTemplate(template),
                            secondary: !activeTemplate(template)
                          }"
                        ></v-divider>
                        <v-card-actions
                          class="
                            d-flex
                            fullwidth
                            align-center
                            justify-space-between
                          "
                        >
                          <div
                            class="
                              col
                              d-flex
                              flex-column
                              align-center
                              justify-center
                            "
                          >
                            <strong class="text-center">Milestones</strong>
                            <span class="fw-900 fs-25">
                              {{ template.milestones_count || 0 }}
                            </span>
                          </div>
                          <div
                            class="
                              d-flex
                              col
                              flex-column
                              align-center
                              justify-center
                            "
                          >
                            <strong class="text-center">Tasks</strong>
                            <span class="fw-900 fs-25">
                              {{ template.tasks_count || 0 }}
                            </span>
                          </div>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                  <Empty v-else headline="No available templates yet"> </Empty>
                </v-card-text>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card min-height="300">
                <v-row dense align="start" justify="space-between">
                  <v-sheet
                    tag="div"
                    class="
                      col-md-6 col-sm-12
                      flex-column
                      fill-height
                      align-start
                      justify-start
                      d-flex
                    "
                  >
                    <strong class="primary--text mb-2 fs-20 fw-700">
                      Select Milestones
                    </strong>
                    <v-treeview
                      v-if="selected_templates_obj.length"
                      v-model="selected_milestones"
                      item-children="milestones"
                      item-text="name"
                      item-key="string_id"
                      :items="selected_templates_obj"
                      selection-type="leaf"
                      selectable
                      return-object
                      open-all
                      dense
                      expand-icon="mdi-chevron-down"
                      on-icon="mdi-checkbox-marked"
                      off-icon="mdi-checkbox-blank-outline"
                      indeterminate-icon="mdi-checkbox-intermediate"
                    >
                      <template v-slot:label="{ item }">
                        <span
                          class="primary--text fw-700"
                          v-if="item.milestones"
                        >
                          Select All From {{ item.name }}
                        </span>
                        <span class="primary--text fw-500" v-else>
                          {{ item.name }}
                        </span>
                      </template>
                    </v-treeview>
                    <Empty v-else headline="No selected templates yet"></Empty>
                  </v-sheet>
                  <v-sheet
                    tag="div"
                    class="
                      col-md-6 col-sm-12
                      flex-column
                      fill-height
                      align-start
                      justify-start
                      d-flex
                      pa-2
                    "
                  >
                    <strong class="primary--text mb-2 fs-20 fw-700">
                      Selected Milestones
                    </strong>
                    <v-card
                      v-if="selected_milestones.length"
                      max-height="300"
                      flat
                      class="fullwidth overflow-y-auto"
                    >
                      <v-chip
                        label
                        outlined
                        class="ma-1 primary--text fw-500"
                        v-for="milestone in selected_milestones"
                        :key="milestone.id"
                      >
                        {{ milestone.name | ucwords }}
                      </v-chip>
                    </v-card>
                    <Empty v-else headline="No selected milestones yet"></Empty>
                  </v-sheet>
                </v-row>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card min-height="300">
                <v-tabs vertical v-model="userTab">
                  <v-tab
                    class="text-none primary--text text-left fw-900"
                    disabled
                  >
                    Assign Task For
                  </v-tab>
                  <template v-if="project_members.length">
                    <v-tab
                      class="d-flex align-center justify-start"
                      v-for="user in project_members"
                      :key="user.id"
                      :href="`#${user.username}`"
                    >
                      <div
                        v-if="taskMapping.hasOwnProperty(user.username)"
                        class="d-flex align-center justify-start"
                      >
                        <v-badge
                          :content="taskMapping[user.username].length || 0"
                          :value="
                            taskMapping[user.username] &&
                            taskMapping[user.username].length
                          "
                          color="primary"
                          left
                          overlap
                        >
                          <v-avatar size="30" class="mr-2">
                            <v-img
                              :lazy-src="settings.loader"
                              contain
                              :src="user.image_url"
                            ></v-img>
                          </v-avatar>
                        </v-badge>
                        <div
                          class="d-flex flex-column align-start justify-start"
                        >
                          <strong class="text-none">{{ user.fullname }}</strong>
                          <span class="text-none caption">
                            {{ user.job_title }}
                          </span>
                        </div>
                      </div>
                    </v-tab>
                  </template>
                  <v-tabs-items
                    v-if="project_members.length > 0"
                    v-model="userTab"
                  >
                    <v-tab-item
                      v-for="user in project_members"
                      :key="user.id"
                      :value="user.username"
                    >
                      <v-sheet
                        color="grey lighten-2"
                        min-height="300"
                        class="pa-3 rounded-5 flex-column fullwidth fill-height"
                      >
                        <v-row
                          dense
                          align="center"
                          class="border-bottom-3 border-color--primary"
                          justify="space-between"
                        >
                          <strong class="primary--text fw-700">
                            Select Task To Assign to {{ user.first_name }}
                          </strong>
                          <label
                            :for="`#${user.username}`"
                            class="d-flex align-center justify-start"
                          >
                            <v-checkbox
                              @change="filterActivated($event, user)"
                              :id="user.username"
                              hide-details="auto"
                              class="my-1"
                              v-model="filterFor[user.username]"
                            >
                            </v-checkbox>
                            <strong class="primary--text fw-700">
                              Show All Task
                              <HelpToolTip
                                text="Show all task will show all tasks regardless of role prerequisites.<br>Unchecking will only show tasks that are applicable for the role the user assigns"
                                class="ml-1"
                              ></HelpToolTip>
                            </strong>
                          </label>
                        </v-row>
                        <v-treeview
                          v-if="
                            taskMapping.hasOwnProperty(user.username) &&
                            individualTasks.hasOwnProperty(user.username) &&
                            individualTasks[user.username].length
                          "
                          v-model="taskMapping[user.username]"
                          item-children="simpletasks"
                          item-text="title"
                          item-key="string_id2"
                          :items="individualTasks[user.username]"
                          selection-type="leaf"
                          selectable
                          open-all
                          dense
                          expand-icon="mdi-chevron-down"
                          on-icon="mdi-checkbox-marked"
                          off-icon="mdi-checkbox-blank-outline"
                          indeterminate-icon="mdi-checkbox-intermediate"
                        >
                          <template v-slot:label="{ item }">
                            <span
                              class="primary--text fw-700"
                              v-if="item.simpletasks"
                            >
                              Select All From {{ item.title }}
                            </span>
                            <span class="primary--text fw-500" v-else>
                              {{ item.title }}
                              <span class="fw-700 fs-10">
                                ({{ item.role ? item.role.name : 'Anyone' }})
                              </span>
                            </span>
                          </template>
                        </v-treeview>
                        <Empty
                          v-else
                          headline="No available tasks on selected milestones"
                        ></Empty>
                      </v-sheet>
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </template>

    <template v-slot:entire-actions>
      <v-btn
        class="text-none px-5"
        dark
        color="primary"
        v-if="stepper === 1"
        @click="goToCreateTemplate"
      >
        Create Milestone Template
      </v-btn>
      <v-btn
        class="text-none px-5"
        dark
        color="primary"
        v-if="stepper > 1"
        @click="back"
      >
        <v-icon left size="25px">mdi-chevron-left</v-icon> Previous
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        class="text-none px-5"
        :disabled="!can_proceed"
        color="primary"
        v-if="stepper < 3"
        @click="next"
      >
        Next <v-icon right size="25px">mdi-chevron-right</v-icon>
      </v-btn>
      <v-btn
        @click="submit"
        class="text-none px-5"
        color="primary"
        :disabled="!step3_passed"
        :loading="btnloading"
        v-if="stepper === 3"
      >
        Submit
        <v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </CustomDialog>
</template>
<script>
import request from '@/services/axios_instance'
import CustomDialog from '@/common/BaseComponents/CustomDialog/CustomDialog.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
export default {
  name: 'SelectTemplateDialog',
  components: {
    CustomDialog
  },
  props: {
    value: { type: Boolean, default: false }
  },
  data: () => ({
    stepper: 1,
    allTasks: [],
    userTab: null,
    dialog: false,
    loading: false,
    btnloading: false,
    templates: [],
    selected_templates: [],
    selected_milestones: [],
    selected_tasks: [],
    taskMapping: {},
    individualTasks: {},
    filterFor: {},
    select_all_templates: false
  }),
  beforeMount() {
    if (this.project_members.length > 0) {
      this.userTab = this.project_members[0].username
      this.createEmptyMapping()
    }
  },
  computed: {
    ...mapGetters('project', ['project_members']),
    is_disabled() {
      return this.loading || this.selected_templates.length === 0
    },
    can_proceed() {
      if (this.stepper === 1) {
        return this.step1_passed
      } else if (this.stepper === 2) {
        return this.step2_passed
      }
      return false
    },
    selected_templates_obj() {
      return this.templates.filter((i) =>
        this.selected_templates.includes(i.id)
      )
    },
    payload() {
      return {
        tasks_map: this.taskMapping,
        milestones: this.selected_milestones.map((i) =>
          i.hasOwnProperty('id') ? i.id : i
        )
      }
    },
    step1_passed() {
      return this.selected_templates.length > 0
    },
    step2_passed() {
      return this.selected_milestones.length > 0
    },
    step3_passed() {
      return this.step1_passed && this.step2_passed
    },
    userWithNoTaskAssigned() {
      const users = []
      Object.entries(this.taskMapping)
        .filter(([, value]) => value.length === 0)
        .forEach(([key, value]) => users.push(key))
      return users.length
        ? _.cloneDeep(this.project_members)
            .filter((i) => users.includes(i.username))
            .map((i) => i.fullname)
        : []
    },
    allHasAssignedTask() {
      return this.userWithNoTaskAssigned.length === 0 ? true : false
    }
  },
  watch: {
    dialog(val) {
      this.$emit('input', val)
    },
    value: {
      handler: function (val) {
        this.dialog = val
        this.stepper = 1
        this.btnloading = false
        this.selected_templates = []
        if (val) {
          this.fillTemplateTree()
          this.createEmptyMapping()
        }
      },
      immediate: true
    },
    select_all_templates: {
      handler: function (val) {
        if (val) {
          this.selected_templates = this.templates.map((i) => i.id)
        } else {
          this.selected_templates = []
        }
      },
      immediate: true
    }
  },
  methods: {
    filterActivated(event, user) {
      this.$set(this.taskMapping, user.username, [])
      if (event) {
        this.$set(
          this.individualTasks,
          user.username,
          _.cloneDeep(this.allTasks)
        )
      } else {
        let role_ids = user.roles.map((i) => i.id)
        this.$set(
          this.individualTasks,
          user.username,
          this.filterMilestoneTasks(role_ids)
        )
      }
    },
    filterMilestoneTasks(role_ids) {
      return _.cloneDeep(this.allTasks)
        .map((m) => {
          let ft = m.simpletasks.filter((t) => role_ids.includes(t.role_id))
          m.simpletasks = ft
          return m
        })
        .filter((m) => m.simpletasks.length)
    },
    createEmptyMapping() {
      const vm = this
      for (let index = 0; index < this.project_members.length; index++) {
        vm.$set(vm.taskMapping, vm.project_members[index].username, [])
        vm.$set(vm.individualTasks, vm.project_members[index].username, [])
        vm.$set(vm.filterFor, vm.project_members[index].username, true)
      }
    },
    fillTemplateTree() {
      this.loading = true
      request
        .get('api/template/milestone/tree-view?all=true')
        .then(({ data }) => {
          this.templates = data.filter((i) => i.tasks_count)
        })
        .finally(() => (this.loading = false))
    },
    fillMilestoneTree() {
      this.loading = true
      request
        .post('api/milestone/task/tree-view?all=true', {
          milestones: this.selected_milestones
        })
        .then(({ data }) => {
          this.allTasks = data
          this.project_members.forEach((user) => {
            this.individualTasks[user.username] = _.cloneDeep(data)
          })
        })
        .finally(() => (this.loading = false))
    },
    cancel() {
      this.dialog = false
    },
    back() {
      this.stepper--
    },
    next() {
      this.stepper++
      if (this.stepper === 3) {
        this.fillMilestoneTree()
      }
    },
    submit() {
      if (!this.step3_passed) return
      if (!this.allHasAssignedTask) {
        this.appConfirmation(
          `${this.joinHumanReadable(
            this.userWithNoTaskAssigned,
            'and'
          )} doesn't have assigned task. Continue submit?`,
          () => {
            this.btnloading = true
            this.$emit('save', this.payload)
          }
        )
      } else {
        this.btnloading = true
        this.$emit('save', this.payload)
      }
    },
    activeTemplate(template) {
      let index = this.selected_templates.findIndex((i) => i === template.id)
      return ~index ? true : false
    },
    toggleTemplate(template) {
      let index = this.selected_templates.findIndex((i) => i === template.id)
      if (~index) {
        this.selected_templates.splice(index, 1)
      } else {
        this.selected_templates.push(template.id)
      }
    },
    goToCreateTemplate() {
      this.$router.push({ name: 'templates' })
    }
  }
}
</script>
<style lang="scss" scoped></style>
