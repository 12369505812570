var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-1 milestones-box",attrs:{"rounded":"","elevation":"3","outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"mb-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":_vm.smAndDown ? 6 : 7}},[_c('h2',{staticClass:"title primary--text"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm._f("ucwords")(_vm.milestone.title),_vm.smAndDown ? 15 : 31))+" ")])]),_c('v-spacer'),_c('div',{staticClass:"mr-3",staticStyle:{"color":"#223a6b"}},[_vm._v(_vm._s(_vm.boxProgress))]),_c('v-btn-toggle',{attrs:{"dense":"","multiple":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary light--text pa-4",attrs:{"small":"","depressed":"","icon":""},on:{"click":function($event){return _vm.$emit('add-task', _vm.milestone)}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(" Add Task ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"primary light--text pa-4",attrs:{"small":"","depressed":"","dark":"","icon":""},on:{"click":function($event){return _vm.$emit('edit-milestone', _vm.milestone)}}},on),[_c('v-icon',{attrs:{"small":"","color":"white"}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v(" Edit Milestone ")])])],1),_c('div',{staticClass:"d-flex ml-3 align-center pb-1"},[_c('v-icon',{attrs:{"color":"#223A6B"},on:{"click":_vm.confirmDeleteMilestone}},[_vm._v(" mdi-close ")])],1)],1),_c('v-progress-linear',{staticClass:"rounded-3",attrs:{"color":"primary","height":"25","value":Math.ceil(_vm.tasksProgress)}})],1),_c('v-card-text',{staticClass:"box-body"},[_c('v-row',{staticStyle:{"background":"#223a6b","padding":"5px 10px","border-radius":"5px","margin-top":"5px"},attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{staticClass:"subtitle-2",staticStyle:{"color":"white"},attrs:{"cols":_vm.smAndDown ? 4 : 5}},[_vm._v(" Task ")]),_c('v-col',{staticClass:"subtitle-2 pl-3 text-center",staticStyle:{"color":"white"},attrs:{"cols":"5"}},[_vm._v(" Status ")]),_c('v-col',{staticClass:"text-center subtitle-2 pl-3",staticStyle:{"color":"white"},attrs:{"cols":_vm.smAndDown ? 3 : 2}},[_vm._v(" Actions ")])],1),_c('draggable',{staticClass:"tasks-drag",attrs:{"scroll-sensitivity":"600","force-fallback":"true"},on:{"change":_vm.handleSorting},model:{value:(_vm.milestone.tasks),callback:function ($$v) {_vm.$set(_vm.milestone, "tasks", $$v)},expression:"milestone.tasks"}},_vm._l((_vm.milestone.tasks),function(task){return _c('v-row',{key:task.id,staticClass:"task-row",staticStyle:{"background":"#e8ebf0","border-bottom":"2px solid white"},attrs:{"dense":"","align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":_vm.smAndDown ? 4 : 5}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"pl-5",staticStyle:{"font-size":"15px","color":"#223a6b","font-weight":"700"}},on),[_vm._v(" "+_vm._s(_vm._f("truncate")(task.title,_vm.mdAndUp ? 45 : 20))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(task.title))])])],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"5"}},[_c('v-chip',{staticClass:"caption align-center justify-center",class:task.status,staticStyle:{"font-weight":"600","font-size":"14px"},attrs:{"pill":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("ucwords")(task.status))+" ")])],1),_c('v-col',{staticClass:"align-content-center text-center",attrs:{"cols":_vm.smAndDown ? 3 : 2}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"task-icon",attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('edit-task', {
                    task: task,
                    milestone: _vm.milestone
                  })}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Edit Task")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"task-icon",attrs:{"color":"primary"},on:{"click":function($event){return _vm.confirmDeleteTask(task)}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Remove Task")])])],1)],1)}),1)],1),_c('DeleteDialog',{attrs:{"title":"Delete Task","text-content":"Are you sure you want to delete this task?"},on:{"delete":_vm.delete_task},model:{value:(_vm.delete_task_dialog),callback:function ($$v) {_vm.delete_task_dialog=$$v},expression:"delete_task_dialog"}}),_c('DeleteDialog',{attrs:{"title":"Delete Milestone","text-content":"Are you sure you want to delete this milestone?<br><span class='error--text'>This will also delete all tasks in this milestone.</span>"},on:{"delete":_vm.delete_milestone},model:{value:(_vm.delete_milestone_dialog),callback:function ($$v) {_vm.delete_milestone_dialog=$$v},expression:"delete_milestone_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }